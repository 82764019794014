@tailwind base;
@tailwind components;
@tailwind utilities;


.active-selection {
  background-color: rgb(100, 150, 250);
  color: #FFF;
}

.alert-error {
  background-color: #CC0000;
  color: #FFF;
}
